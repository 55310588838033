.technology {
    margin-top: 4em;
    padding-top: 1.5em;
}

.tech-header-section {
    font-family: "Helvetica", sans-serif;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 85%;
}

.tech-header-title {
    font-family: "Helvetica", sans-serif;
    font-weight: 600;
    color: #0d25af;
    font-size: 1.8em;
}

.tech-header-subtitle {
    font-family: "Roboto", sans-serif;
    padding-top: 10px;
    color: #3b4246;
    font-size: 22px;
    line-height: 35px;
}

.cycling {
    position: relative;
    font-family: "Helvetica", sans-serif;
}

.cycling-section {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.development-architecture {
    padding-top: 3em;
}

.tech_offers {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tech_offers_content {
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    color: #3b4246;
    max-width: 75%;
    margin: 0 auto;
}

.technology-item {
    border-radius: 10px;
    background-color: #ffffff;
}

.techcarousel-image {
    max-height: 40rem;
    width: 100%
}

.carousel-control-next, .carousel-control-prev {
    color: black!important;
}


.tech_carousel_container {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
}

@media (max-width: 320px) {
    .tech-header-section {
        max-width: 100%;
        margin: 0 auto;
    }
    .tech-header-title {
        font-size: 1.625em
    }
    .tech-header-subtitle{
        text-align: center;
    }
    .cycling-section {
        max-width: 100%;
        margin: 0 auto;
    }
    .tech_carousel_container {
        max-width: 100%;
        margin: 0 auto;
    }
 
    .tech_offers_content {
        max-width: 100%;
        margin:0 auto;
    }
}

/*Most of the Smartphones Mobiles (Portrait)*/

@media (min-width: 320px) and (max-width: 480px) {
    .tech-header-section {
        max-width: 100%;
        margin: 0 auto;
    }
    .tech-header-title {
        font-size: 1.625em
    }
    .tech-header-subtitle{
        text-align: center;
    }
    .cycling-section {
        max-width: 100%;
        margin: 0 auto;
    }
    .tech_carousel_container {
        max-width: 100%;
        margin: 0 auto;
    }
   
    .tech_offers_content {
        max-width: 100%;
        margin:0 auto;
    }
}

/*Low Resolution Tablets, Mobiles (Landscape)*/

@media (min-width: 481px) and (max-width: 768px) {
    .tech-header-section {
        max-width: 100%;
        margin: 0 auto;
    }
    .tech-header-subtitle{
        text-align: center;
    }
    .cycling-section {
        max-width: 100%;
        margin: 0 auto;
    }
    .tech_carousel_container {
        max-width: 100%;
        margin: 0 auto;
    }
   
    .tech_offers_content {
        max-width: 100%;
        margin:0 auto;
    }
}

/*Tablets, Ipads */

@media (min-width: 769px) and (max-width: 1024px) {
    .tech-header-section {
        max-width: 100%;
        margin: 0 auto;
    }
   
    .cycling-section {
        max-width: 100%;
        margin: 0 auto;
    }
    .tech_carousel_container {
        max-width: 100%;
        margin: 0 auto;
    }
    .tech_offers_content {
        max-width: 100%;
        margin:0 auto;
    }
}

/*Laptop*/

@media (min-width: 1024px) and (max-width: 1280px) {}

/*Big Screen*/

@media (min-width: 1281px) {}
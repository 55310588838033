@font-face {
    font-family: 'Helvetica';
    src: url(../fonts/Helvetica/Helvetica.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'AvenirNextLTPro-Bold';
    src: url(../fonts/AvenirNextLTPro/AvenirNextLTPro-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'Paytone One';
    src: url(../fonts/Paytone_One/PaytoneOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Circular Std Book';
    src: url(../fonts/CircularStd/CircularStd-Book.ttf) format('truetype');
}

@font-face {
    font-family: "Niconne";
    src: url(../fonts/Niconne/Niconne-Regular.ttf) format('truetype');
}
.active-scroll{
    color: #00AC52 !important;
}

.link_menu::before{
    width: 0%;
    bottom: 2px;
}
.link_menu:hover::before {
    width: 100%;
}
.navbar_row {
    background-color: #ffffff;
}
.navbar_container{
    max-width: 85%;
    margin: 0 auto;
}
.logoimage {
    width: auto;
    height: 2em;
}

.link {
padding: 0.3em 0;
    margin:0 0.6em;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #0d25af;
    font-size: 1.1250em;
      display: inline-block;
      position: relative;
      cursor: pointer;
}
.link::before{
    transition: 300ms;
    height: 4px;
    content: "";
    position: absolute;
    background-color: #00AC52;
}

.link:hover {
    text-decoration: none;
    color: #00AC52;
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown_items {
    display: none;
    position: absolute;
    background-color: #0d25af;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown_items .dropdown_item {
    cursor: pointer;
    font-family: "Helvetica", sans-serif;
    font-size: 1.1250em;
    color: #ffffff;
    padding: 5px 10px;
    text-decoration: none;
    display: block;
}

.dropdown_items .dropdown_item:hover {
    background-color: #00AC52
}

.dropdown:hover .dropdown_items {
    display: block;
}

.subnavbtn {
    display: none;
    float: right;
    z-index: 1;
  margin-top: 1em;
}

.hero_banner_sec {
    padding-top: 4em;
}

.hero_banner_container {
    max-width: 85%;
    margin: 0 auto;
}

.banner_row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero_banner {
    background: radial-gradient(circle, rgba(13, 37, 175, 1) 0%, rgba(7, 19, 88, 1) 100%);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner_header_title {
    font-family: "Helvetica", sans-serif;
    color: #ffffff;
    font-size: 2.5em;
    font-weight: 900;
    padding-bottom: 20px;
}

.banner_header_subtitles {
    padding-bottom: 50px;
}

.banner_subtitle {
    font-family: "Helvetica", sans-serif;
    color: #ffffff;
    font-size: 1.4em;
    font-weight: 550;
    list-style-position: outside;
}

.banner_container {
    padding-top: 5em;
}

.banner_header_title .letter {
    display: inline-block;
    line-height: 1em;
}

.slide-right, .slide-left {
    width: 100%;
}

/***** Slide Right *****/

.slide-right {
    animation: 3s slide-right;
}

@keyframes slide-right {
    from {
        margin-left: 30%;
    }
    to {
        margin-left: 0%;
    }
}

/***** Slide Left *****/

.slide-left {
    animation: 3s slide-left;
}

@keyframes slide-left {
    from {
        margin-left: -10%;
    }
    to {
        margin-left: 0%;
    }
}

/***** Misc Styles *****/

/***** FadeIn Underline *****/

.fade-in {
    animation: fadeIn ease 3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

.startButton {
    width: 40%;
    height: 2.2em;
    font-family: "Helvetica", sans-serif;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    color: white;
    /*background-image: linear-gradient(to right, #0313b3, #061cc5, #0826d8, #062feb, #0038ff);*/
    background-image: radial-gradient(circle, #07cf67, #06c863, #05c25f, #03bb5b, #02b557, #02af54, #01a851, #01a24e, #029b4b, #039348, #048c45, #058542);
    border-radius: 5px;
    border: 0px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.startButton:focus {
    outline: none;
}

.startButton:hover {
    color: #fff;
    transform: translateY(-7px);
    outline: none;
}

.pulse_circle {
    margin-left: 20px;
    margin-top: 5px;
    content: "";
    position: absolute;
    min-width: 1em;
    min-height: 1em;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #ffffff;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

.orange {
    background: #ffffff;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.9);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
}

.header-title {
    font-family: "Helvetica", sans-serif;
    font-weight: 600;
    color: #0d25af;
    text-align: center;
    font-size: 1.8em;
}

.sub-title {
    font-family: "Roboto", sans-serif;
    ;
    color: #3b4246;
    font-size: 1.4em;
    text-align: center;
}

.header-sub-title {
    font-family: "Helvetica", sans-serif;
    font-weight: 600;
    color: #0d25af;
    font-size: 1.8em;
}

.content {
    font-family: "Roboto", sans-serif;
    font-size: 1.375em;
    color: #3b4246; 
}
.career-title{
    font-family: "Helvetica", sans-serif;
    font-weight: 600;
    color: #0d25af;
    font-size: 1.4em;
}
.career_content{
    max-width:85%;
    margin: 0 auto;
    text-align: center;
}
.service-container {
    width: 100%;
    margin: 0 auto;
}

.service-card-container {
    background-color: transparent;
    width: 21.875em;
    height: 21.875em;
    padding-bottom: 1.25em;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    -moz-perspective: 1000;
    perspective: 1000;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.service-card-container:hover .service-back-face {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.service-card-container:hover .service-front-face {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.service-card-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: -webkit-transform .7s ease;
    transition: -webkit-transform .7s ease;
    transition: transform .7s ease;
    transition: transform .7s ease, -webkit-transform .7s ease;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.service-front-face, .service-back-face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .7s ease;
    transition: -webkit-transform .7s ease;
    transition: transform .7s ease;
    transition: transform .7s ease, -webkit-transform .7s ease;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.service-front-face {
    background-image: url('../assets/images/service_background.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #696b6d;
    padding: 10px;
    border-radius: 5px;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.service-back-face {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /*background-image: linear-gradient(to top, #0313b3, #061cc5, #0826d8, #062feb, #0038ff);
      box-shadow: 10px 10px 50px #0038ff;*/
    background-image: url('../assets/images/service_background2.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    padding: 10px;
    border-radius: 5px;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.service-container {
    padding-top: 3.125em;
    padding-bottom: 3.125em;
}

.service-card-image {
    height: 10em;
    width: auto;
}

.header-h2 {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 1.3em;
    font-weight: 600;
}

.content-p {
    color: #ffffff;
    max-width: 300px;
    margin: 0 auto;
    font-family: "Roboto", sans-serif;
    font-size: 1.2em;
    font-weight: 400;
}

.service-header-h2 {
    margin-top: 20px;
    font-family: "Helvetica", sans-serif;
    color: #0d25af;
    max-width: 250px;
    margin: 0 auto;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
}

.readmore-link {
    margin-top: 18px;
    margin-right: 100px;
    float: right;
    font-family: "Roboto", sans-serif;
    color: #0d25af;
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
}

.readmore-link:hover {
    text-decoration: none;
    color: #00AC52;
}

.arrow-right {
    animation: slide1 1s ease-in-out infinite;
    margin-left: 9px;
}

.round {
    position: absolute;
    border: 0px solid #0d25af;
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

#cta {
    width: 100%;
    cursor: pointer;
    position: absolute;
}

#cta .arrow {
    left: 30%;
}

.arrow {
    position: absolute;
    bottom: 0;
    margin-left: 0px;
    width: 18px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: contain;
    top: 11px;
}

.arrow1 {
    margin-left: 8px;
}

.arrow2 {
    margin-left: -8px;
}

.next {
    background-image: url('../assets/images/services/arrow.svg');
}

.readmore-link:hover .next {
    background-image: url('../assets/images/services/arrow-2.svg');
}

@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
    25% {
        opacity: 0;
        transform: translateX(10px) scale(0.9);
    }
    26% {
        opacity: 0;
        transform: translateX(-10px) scale(0.9);
    }
    55% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.arrow.primera.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.round .arrow {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.round .arrow.primera {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes slide1 {
    0%, 100% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(10px, 0);
    }
}

.offers {
    background: radial-gradient(circle, rgba(13, 37, 175, 1) 0%, rgba(7, 19, 88, 1) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 12rem;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offers-content {
    margin: 0 auto;
    max-width: 75%;
    color: #ffffff;
    font-family: "Niconne", cursive;
    font-size: 2.8125em;
    font-weight: 400;
}

.ideaanddelivery {
    width: 100%;
}

.ideacontainer {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
}

.poster-h1 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.7em;
    font-weight: 700;
    color: #0d25af;
}

.poster-content-p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    color: #0d25af;
}

.poster-contents {
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

.poster-1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gotidea_content {
    text-align: center;
}

.delivery_content{
    max-width: 75%;;
}
.gotidea_whatsapp_button {
    background-color: transparent;
    border-radius: 10px;
    border: 3px solid #00AC52;
    color: #00AC52;
    font-family: 'Helvetica', sans-serif;
    font-size: 1.2em;
    width: 8.5em;
    height: 2.5em;
}

.gotidea_whatsapp_button:focus {
    outline: none;
}

.gotidea_contactus_button {
    background-color: transparent;
    border-radius: 10px;
    border: 3px solid #0d25af;
    color: #0d25af;
    font-family: 'Helvetica', sans-serif;
    font-size: 1.2em;
    width: 8.5em;
    height: 2.5em;
}

.gotidea_contact_link{
    color: #0d25af;
  
}
.gotidea_contact_link:hover{
text-decoration: none;
color: #0d25af;
}
.gotidea_contactus_button:focus {
    outline: none;
}

.poster-image {
    max-width: 37.5em;
    max-height: 25em;
}

.usecase {
    padding-top: 2.5em;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2.5em;
}
.usecaseContainer {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
}
.usecase_header {
    font-family: "Helvetica", sans-serif;
    font-size: 1.3em;
    font-weight: 600;
    color: #00ac52;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.usecase_content {
    font-family: "Roboto", sans-serif;
    font-size: 1.4em;
    color: #3b4246;
    padding-bottom: 50px;
    text-align: center;
}

.usecase_container {
    background-color: transparent;
    width: 19rem;
    height: 22rem;
    position: relative;
    margin-bottom: 20px;
}

.usecase_overlay {
    width: 19rem;
    height: 0;
    background-color: #00ac52;
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 100%;
    overflow: hidden;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    border-radius: 5px;
}

.usecaseColumn {
    margin: 0 auto;
}

.usecase_header_container {
    background-color: white;
    border-radius: 5px;
    height: 22rem;
    width: 19rem;
    text-align: center;
    box-shadow: 1px 1px 20px rgb(231, 231, 231);
}

.usecase_container:hover .usecase_overlay {
    bottom: 0;
    height: 100%;
}

.usecase_overlay_contents {
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    color: white;
    width: 100%;
    line-height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.usecase_image {
    width: 200px;
    height: 200px;
}


.price_sec {
    padding-top: 4em;
}

.price_header_content {
    padding-top: 2.5em;
}
.price_header_sec{
    max-width: 85%;
    margin:0 auto;
}
.pricing {
    margin-top: -12em;
}
.pricing_sec{
    max-width: 85%;
    margin:0 auto;
}
.price_back_box {
    background: radial-gradient(circle, rgba(13, 37, 175, 1) 0%, rgb(6, 27, 150) 100%);
    width: 100%;
    height: 26rem;
    margin: 0 auto;
    ;
}

.price-card {
    background-color: white;
    margin: 0 auto;
    transition: transform .2s;
    height: 95%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.price-card:hover {
    z-index: 1;
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
}

.rating {
    font-family: "Helvetica", sans-serif;
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    color: #3b4246;
}

.card-subtitle {
    font-family: "Helvetica", sans-serif;
    text-align: center;
    font-size: 1em;
    color: #3b4246;
    list-style-type: none;
}

.level {
    font-family: "Helvetica", sans-serif;
    font-size: 1.2em;
    font-weight: 550;
    text-align: center;
    color: #3b4246;
}

.duration {
    font-family: "Helvetica", sans-serif;
    font-size: 1em;
    text-align: center;
    color: #3b4246;
}

.item {
    text-align: center;
    font-family: "Helvetica", sans-serif;
    font-size: 1em;
    color: #3b4246;
    line-height: 1.6;
    list-style-type: none;
}

.price-header-title {
    color: #00ac52;
    font-family: "Helvetica", sans-serif;
    font-size: 1.2em;
    font-weight: 600;
}

.price-button {
    font-family: "Roboto", sans-serif;
    border: 0.8px solid rgb(226, 226, 226);
    font-weight: bold;
    background: radial-gradient(circle, rgba(2, 45, 250, 1) 0%, rgba(3, 38, 214, 1) 81%, rgba(0, 35, 207, 1) 95%, rgba(2, 34, 219, 1) 100%);
    /*background-image: linear-gradient(to right, #0313b3, #061cc5, #0826d8, #062feb, #0038ff);*/
    /*background-image: radial-gradient(circle, #0026ff, #0026f8, #0126f2, #0125eb, #0225e5, #0225de, #0325d8, #0425d1, #0625c8, #0825c0, #0a25b7, #0d25af);*/
    font-size: 1.2em;
    font-weight: 500;
    width: 90%;
    height: 40px;
    border: 0px;
    color: white;
    border-radius: 5px;
}

.price-content {
    font-family: "Roboto", sans-serif;
    font-size: 1.4em;
    color: #ffffff;
}

.price_border {
    border-bottom: 1px solid #00ac52;
}



.header-title {
    color: #0d25af;
}



.errorMsg {
    font-family: "Helvetica", sans-serif;
    font-size: 18px;
    color: red;
}
.contact_container{
    padding-top: 2em;
}
.contactform {
    padding: 30px;
}

.contact-btn {
    padding: 5px 10px 5px;
}

.contactus-button {
    width: 100%;
    height: 2.2em;
    font-family: "Roboto", sans-serif;
    font-size: 1.3em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 600;
    color: white;
    /*background-image: linear-gradient(to right, #0313b3, #061cc5, #0826d8, #062feb, #0038ff);*/
    background-image: radial-gradient(circle, #07cf67, #06c863, #05c25f, #03bb5b, #02b557, #02af54, #01a851, #01a24e, #029b4b, #039348, #048c45, #058542);
    border: none;
    border-radius: 5px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.contactus-button:hover {
    background-color: #00AC52;
    color: #fff;
    transform: translateY(-7px);
    outline-style: none;
    border: none;
}

.contactus-button:focus {
    outline: none;
}

@keyframes ring {
    0% {
        width: 30px;
        height: 30px;
        opacity: 1;
    }
    100% {
        width: 300px;
        height: 300px;
        opacity: 0;
    }
}
.footer {
    background-image: url('../assets/images/footer-background.svg');
    background-repeat: no-repeat;
    background-position: top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.footer_whatsapp_button{
    background-color: transparent;
    border-radius: 10px;
    border: 3px solid #ffffff;
    color: #ffffff;
    font-family: 'Helvetica', sans-serif;
    font-size: 1.2em;
    width: 8.5em;
    height: 2.5em;
}
.footer_whatsapp_button:focus{
outline: none;
}
.footer-address {
    max-width: 85%;
    margin: 0 auto;
    background-color: transparent;
    color: #ffffff;
    padding-top: 18rem;
}

.footer-copyright {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.171);
}

.footer_copyright_content {
    padding-top: 10px;
    text-align: center;
    color: rgb(133, 133, 133);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.footer_link, .footer_address, .footer_media {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #ffffff;
    text-decoration: none;
    line-height: 35px;
    list-style-type: none;
}

.footer_link:hover {
    color: #00AC52;
    text-decoration: none;
}

@media (max-width: 320px) {
    .content{
        text-align: center;
    }
    .header-sub-title{
        font-size: 1.7em;
        text-align: center;
    }
    .link_menu:hover::before {
        width: 0;
    }
    .navbar_container{
        max-width: 100%;
        margin: 0 auto;
    }
    .subnavbtn {
        display: inline-block;
    }
    .hero_banner_container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .logoimage {
        width: auto;
        height: 1.6em;
    }
    .banner_header_title {
        text-align: center;
        font-size: 1.3em;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
        margin-top: 2rem;
    }
    .startButton{
        font-size: 0.7em;
    }
    .pulse_circle {
        margin-left: 0.5em;
      margin-top: 0.2em ;
    }
    .footer-address {
        max-width: 100%;
        margin: 0 auto;
    }
    .offers-content {
        font-size: 1.6em;
        max-width: 100%;
        margin: 0 auto;
    }
    .service-card-container {
        background-color: transparent;
        width: 19em;
        height: 19em;
    }
    .ideacontainer {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .usecaseContainer {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .pricing {
        margin-top: -12em;
    }
    .price_back_box {
        height: 61rem;
    }
    .price_header_sec{
        max-width: 100%;
        margin:0 auto;
        padding: 0 15px;
    }
    .pricing_sec{
        max-width: 100%;
        margin:0 auto;
        padding: 0 15px;
    }
    .career_content{
        max-width: 100%;
        margin: 0 auto;
    }
    .link {
        padding: 0.2em 0;
            margin:0 0.2em;
    }
}

/*Most of the Smartphones Mobiles (Portrait)*/

@media (min-width: 320px) and (max-width: 480px) {
    .content{
        text-align: center;
    }
    .header-sub-title{
       
        text-align: center;
    }
    .link {
        padding: 0.2em 0;
            margin:0 0.2em;
    }
    .link_menu:hover::before {
        width: 0;
    }
    .navbar_container{
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .subnavbtn {
        display: inline-block;
    }
    .hero_banner_container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .banner_header_title {
        text-align: center;
        font-size: 1.8em;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
        margin-top: 2rem;
    }
    .startButton{
        font-size: 1em;
    }
    .pulse_circle {
        margin-left: 0.5em;
      margin-top: 0.2em ;
    }
    .offers-content {
        font-size: 1.6em;
        max-width: 100%;
        margin: 0 auto;
    }

    .ideacontainer {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .usecaseContainer {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .pricing {
        margin-top: -12em;
    }
    .price_back_box {
        height: 46em;
    }
    .price_header_sec{
        max-width: 100%;
        margin:0 auto;
        padding: 0 15px;
    }
    .pricing_sec{
        max-width: 100%;
        margin:0 auto;
        padding: 0 15px;
    }
    .footer-address {
        max-width: 100%;
        margin: 0 auto;
    }
    .career_content{
        max-width: 100%;
        margin: 0 auto;
    }
  
}

/*Low Resolution Tablets, Mobiles (Landscape)*/

@media (min-width: 481px) and (max-width: 768px) {
    .content{
        text-align: center;
    }
    .header-sub-title{
       
        text-align: center;
    }
    .link {
        padding: 0.2em 0;
            margin:0 0.2em;
    }
    .link_menu:hover::before {
        width: 0;
    }
    .navbar_container{
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .subnavbtn {
        display: inline-block;
    }
    .hero_banner_container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .banner_header_title {
        text-align: center;
        font-size: 2em;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
        margin-top: 2rem;
    }
    .startButton{
        font-size: 1em;
    }
    .pulse_circle {
        margin-left: 0.5em;
      margin-top: 0.2em ;
    }
    .offers-content {
        font-size: 1.6em;
        max-width: 100%;
        margin: 0 auto;
    }
    .ideacontainer {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .usecaseContainer {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .footer-address {
        max-width: 100%;
        margin: 0 auto;
    }
    .pricing {
        margin-top: -12em;
    }
    .price_back_box {
        height: 36rem;
    }
    .price_header_sec{
        max-width: 100%;
        margin:0 auto;
        padding: 0 15px;
    }
    .pricing_sec{
        max-width: 100%;
        margin:0 auto;
        padding: 0 15px;
    }
    .career_content{
        max-width: 100%;
        margin: 0 auto;
    }

}

/*Tablets, Ipads */

@media (min-width: 769px) and (max-width: 1024px) {
    .content{
        text-align: center;
    }
    .header-sub-title{
        text-align: center;
    }
    .link {
        padding: 0.3em 0;
            margin:0 0.2em;
    }
    .link_menu:hover::before {
        width: 0;
    }
    .navbar_container{
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .hero_banner_container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .subnavbtn {
        display: inline-block;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
        margin-top: 2rem;
    }
    .offers-content {
        font-size: 2.5em;
        max-width: 100%;
        margin: 0 auto;
    }
    .ideacontainer {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .usecaseContainer {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .footer-address {
        max-width: 100%;
        margin: 0 auto;
    }
    .pricing {
        margin-top: -12em;
    }
    .price_back_box {
        height: 30rem;
    }
    .price_header_sec{
        max-width: 100%;
        margin:0 auto;
        padding: 0 15px;
    }
    .pricing_sec{
        max-width: 100%;
        margin:0 auto;
        padding: 0 15px;
    }
    .career_content{
        max-width: 100%;
        margin: 0 auto;
    }
 
}

/*Laptop*/

@media (min-width: 1024px) and (max-width: 1280px) {
    .hero_banner_container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
   
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
        margin-top: 2rem;
    }
    .subnavbtn {
        display: none;
    }

}

/*Big Screen*/

@media (min-width: 1281px) {}
.why-lm-icon {
    margin-top: -80px;
    height: 150px;
    width: 150px;
}

.why-lm-header {
    padding-top: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 550;
    color: #0d25af;
    font-size: 20px;
    text-align: center;
}
.whylm-container {
    max-width: 95%;
    margin: 0 auto;
}

.whylm {
    width: 100%;
}

.whylm_content {
    text-align: center;
    font-size: 22px;
    font-family: "Roboto", sans-serif;
    color: #3b4246;
}

.why-item {
    border-radius: 10px;
    margin-top: 60px;
    margin-bottom: 20px;
    height: 10rem;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 1px 1px 20px rgb(231, 231, 231);
    color: #181823;
}

.why-lm-carousel {
    padding-top: 3.125em;
    margin: 0 auto;
}

.owl-prev, .owl-next {
    position: absolute;
    top: 30%;
    border-radius: 100% !important;
}

.owl-prev span {
    font-size: 3.5em;
    text-align: center;
}

.owl-next span {
    font-size: 3.5em;
    text-align: center;
}

.owl-prev {
    left: -1em;
    min-width: 5em;
}

.owl-next {
    right: -1em;
    min-width: 5em;
}

.owl-next:focus, .owl-next:active {
    outline: none;
}

.owl-prev:focus, .owl-prev:active {
    outline: none;
}

.owl-nav .owl-prev {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.owl-nav .owl-next {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.owl-nav .owl-prev:hover {
    background-color: rgba(163, 163, 163, 0.664) !important;
}

.owl-nav .owl-next:hover {
    background-color: rgba(163, 163, 163, 0.664) !important;
}

@media (max-width: 320px) {
    .owl-nav .owl-prev:hover {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-next:hover {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-prev{
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-next{
        background-color: transparent !important;
        color: #3b4246 !important;
    }
}

/*Most of the Smartphones Mobiles (Portrait)*/

@media (min-width: 320px) and (max-width: 480px) {
    .owl-nav .owl-prev:hover {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-next:hover {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-prev {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-next {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
}

/*Low Resolution Tablets, Mobiles (Landscape)*/

@media (min-width: 481px) and (max-width: 768px) {
    .owl-nav .owl-prev:hover {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-next:hover {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-prev {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-next {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
}

/*Tablets, Ipads */

@media (min-width: 769px) and (max-width: 1024px) {
    .owl-nav .owl-prev:hover {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-next:hover {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-prev {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
    .owl-nav .owl-next {
        background-color: transparent !important;
        color: #3b4246 !important;
    }
}

/*Laptop*/

@media (min-width: 1024px) and (max-width: 1280px) {}

/*Big Screen*/

@media (min-width: 1281px) {}
.icon-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 30%;
    z-index: 1;
    width: 8rem;
}

.sec-link {
    cursor: pointer;
    display: block;
    padding: 16px;
    transition: all 0.3s ease;
    text-decoration: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: #0d25af;
    font-weight: 650;
    list-style-type: disc;
    text-transform: uppercase;
    text-decoration: none;
    padding: 18px 0px;
    margin: 0px 10px;
    position: relative;
}

.sec-link:hover {
    opacity: 1;
    color: #00ac52;
    text-decoration: none;
}

.sec-link::before {
    transition: 300ms;
    height: 5px;
    content: "";
    position: absolute;
    background-color: #00ac52;
}

.sec-link::before {
    width: 0;
    bottom: 10px;
}

.sec-link:hover::before {
    width: 95%;
}

.active-scroll {
    color: #00ac52;
}

.how {
    margin-top: -20em;
    padding-top: 1.5em;
}

.process_sec {
    padding-top: 4em;
}

.process-section {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
}

.icon {
    height: 50px;
    width: auto;
}

.describe_sec {
    padding-top: 4em;
}

.describe_section {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
}

.aboutus-container {
    padding-top: 4em;
}

.about_section {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
}

.about-content-subtitle {
    margin-left: 6rem;
    line-height: 20px;
}

.faq_sec {
    padding-top: 4em;
}

.faq_section {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
}

.faq-title {
    font-family: "Roboto", sans-serif;
    font-size: 1.375em;
    color: #3b4246;
}

.faq-content {
    padding-left: 30px;
    font-family: "Roboto", sans-serif;
    font-size: 1.25em;
    line-height: 30px;
    color: #434546;
}

.accordion {
    cursor: pointer;
}

.card-header {
    background-color: white!important;
    border-bottom: none!important;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.partner_sec {
    padding-top: 4em;
}

.partner_section {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
}

.partner-subtitle {
    font-family: "Helvetica", sans-serif;
    color: #0d25af;
    font-size: 1.6em;
}

@media (max-width: 320px) {
    .process-section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .describe_section {
        max-width: 100%;
        margin: 0 auto;
    }
    .about_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .about-content-subtitle {
        margin-left: 0;
        line-height: 20px;
    }
    .faq_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .icon-bar {
        display: none;
    }
    .how {
        margin-top: 0;
    }
    .partner_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
}

/*Most of the Smartphones Mobiles (Portrait)*/

@media (min-width: 320px) and (max-width: 480px) {
    .process-section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .describe_section {
        max-width: 100%;
        margin: 0 auto;
    }
    .about_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .about-content-subtitle {
        margin-left: 0;
        line-height: 20px;
    }
    .faq_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .icon-bar {
        display: none;
    }
    .how {
        margin-top: 0;
    }
    .partner_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
}

/*Low Resolution Tablets, Mobiles (Landscape)*/

@media (min-width: 481px) and (max-width: 768px) {
    .process-section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .describe_section {
        max-width: 100%;
        margin: 0 auto;
    }
    .about_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .about-content-subtitle {
        margin-left: 0;
        line-height: 20px;
    }
    .faq_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .icon-bar {
        display: none;
    }
    .how {
        margin-top: 0;
    }
    .partner_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
}

/*Tablets, Ipads */

@media (min-width: 769px) and (max-width: 1024px) {
    .process-section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .describe_section {
        max-width: 100%;
        margin: 0 auto;
    }
    .about_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .about-content-subtitle {
        margin-left: 0;
        line-height: 20px;
    }
    .faq_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .icon-bar {
        display: none;
    }
    .how {
        margin-top: 0;
    }
    .partner_section {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
}

/*Laptop*/

@media (min-width: 1024px) and (max-width: 1280px) {}

/*Big Screen*/

@media (min-width: 1281px) {}
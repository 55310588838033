@font-face {
    font-family: 'Helvetica';
    src: url(../fonts/Helvetica/Helvetica.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'AvenirNextLTPro-Bold';
    src: url(../fonts/AvenirNextLTPro/AvenirNextLTPro-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'Paytone One';
    src: url(../fonts/Paytone_One/PaytoneOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Circular Std Book';
    src: url(../fonts/CircularStd/CircularStd-Book.ttf) format('truetype');
}

@font-face {
    font-family: "Niconne";
    src: url(../fonts/Niconne/Niconne-Regular.ttf) format('truetype');
}
.services{
margin-top: 4em;
padding-top: 1.5em;
}
.service_sec {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
}
.serviceins-title {
    font-family: "Helvetica", sans-serif;
    font-weight: 600;
    color: #0d25af;
    font-size: 28px;
}

.serviceins-title{

    font-family: "Helvetica",sans-serif;
    font-weight:600;
   color:#0d25af;
   font-size:28px;
  }
.serviceins-content {
    font-family: "Roboto", sans-serif;
    font-size: 1.375em;
    color: #3b4246;
}

.industry-container {
    max-width: 85%;
    margin: 0 auto;
    padding: 0 15px;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.industry-rows {
    margin-bottom: 20px;
}

.ins-col {
    padding: 5px;
}

.ins-button {
    font-size: 30px;
    color: #0d25af;
    background-color: transparent;
    border: 0;
    text-decoration: none;
    cursor: pointer;
}

.ins-button:hover {
    display: block;
    cursor: pointer;
    background-color: transparent;
    color: #0d25af;
    text-decoration: none;
    display: block;
}

.owl-dots button.owl-dot:focus {
    outline: none;
}

.owl-stage {
    display: flex;
}

.thumbnail {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
}

.caption {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.flex-text {
    flex-grow: 1;
}

.service_offers {
    height: 12rem;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service_offers_content {
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    color: #3b4246;
    max-width: 75%;
    margin: 0 auto;
}

@media (max-width: 320px) {
    .service_sec {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .industry-container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .service_offers_content {
        max-width: 100%;
        margin: 0 auto;
    }
}

/*Most of the Smartphones Mobiles (Portrait)*/

@media (min-width: 320px) and (max-width: 480px) {
    .service_sec {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .industry-container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .service_offers_content {
        max-width: 100%;
        margin: 0 auto;
    }
}

/*Low Resolution Tablets, Mobiles (Landscape)*/

@media (min-width: 481px) and (max-width: 768px) {
    .service_sec {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .industry-container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .service_offers_content {
        max-width: 100%;
        margin: 0 auto;
    }
}
/*Tablets, Ipads */

@media (min-width: 769px) and (max-width: 1024px) {
    .service_sec {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .industry-container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .service_offers_content {
        max-width: 100%;
        margin: 0 auto;
    }
}
/*Laptop*/

@media (min-width: 1024px) and (max-width: 1280px) {}

/*Big Screen*/

@media (min-width: 1281px) {}